import React, { useEffect } from 'react'
import { PageProps, Link } from 'gatsby'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'
import { Head, Menu, Footer } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import * as sc from '~/assets/styl/ContainerList.module.styl'

const BeneficiosLista = ({
  location,
  pageContext = {},
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const context = useApi(
    pageContext,
    `page-deliverables/${
      slug || (pageContext as ApiBenefitsList).department?.slug
    }/${slug ? '' : (pageContext as ApiBenefitsList).benefit?.slug}`
  ) as ApiBenefitsList

  const { department, benefit, deliverables } = context || {}

  const { setLoading } = useContext()
  useEffect(() => {
    setLoading(!benefit?.name)
  }, [benefit])

  return benefit?.name ? (
    <>
      <Head location={location} />
      <Menu location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>{benefit.name}</h1>
          <ul className={sc.list}>
            {deliverables.map(({ slug, title }, index) => {
              // Define o link condicionalmente
              let customHref = `/${department.slug}/${benefit.slug}/${slug}`

              if (title === 'English pass') {
                customHref = '/unicred-corretora/parcerias/english-pass'
              } else if (
                title === 'Cursos preparatórios para Certificações Financeiras'
              ) {
                customHref =
                  '/unicred-corretora/parcerias/cursos-preparatorios-para-certificacoes-financeiras'
              }

              return (
                <li key={index} style={{ color: benefit.color }}>
                  <p>
                    <span>Saiba mais</span>
                  </p>
                  <Link to={customHref}>{title}</Link>
                </li>
              )
            })}
          </ul>
        </section>
      </main>

      <Footer location={location} />
    </>
  ) : (
    <></>
  )
}

export default BeneficiosLista
